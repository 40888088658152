import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	
	{
		path: '/'
		,name: 'dashboard'
		,component: () => import('@/view/Main/index.vue')
		//,component: () => import('@/view/Auth/ComingSoon.vue')
	}
	,{
		path: '/Login'
		,name: 'AuthLogin'
		,component: () => import('@/view/Auth/Login.vue')
	}
	,{
		path: '/FindId'
		,name: 'AuthFindId'
		,component: () => import('@/view/Auth/FindId.vue')
	}
	,{
		path: '/FindPw'
		,name: 'AuthFindPw'
		,component: () => import('@/view/Auth/FindPw.vue')
	}
	,{
		path: '/Agent/Detail'
		,name: 'AgentDetail'
		,component: () => import('@/view/Agent/AgentDetail.vue')
	}
	,{
		path: '/Agent/Sales/List/:page?'
		,name: 'AgentSalesList'
		,component: () => import('@/view/AgentSales/AgentList.vue')
	}
	,{
		path: '/Agent/Sales/Item/:index?'
		,name: 'AgentSalesItem'
		,component: () => import('@/view/AgentSales/AgentItem.vue')
	}
	,{
		path: '/Agent/Sales/Detail/:index'
		,name: 'AgentSaelsDetail'
		,component: () => import('@/view/AgentSales/AgentItemDetail.vue')
	}
	,{
		path: '/Franchisee/List/:page?'
		,name: 'FranchiseeList'
		,component: () => import('@/view/Franchisee/FranchiseeList.vue')
	}
	,{
		path: '/Franchisee/Detail/:pgMerchNo'
		,name: 'FranchiseeDetail'
		,component: () => import('@/view/Franchisee/FranchiseeDetail.vue')
	}
	,{
		path: '/Settlement/Agent/List/:page?'
		,name: 'SettlementAgentList'
		,component: () => import('@/view/Settlement/Agent/SettlementAgentList.vue')
	}
	,{
		path: '/Settlement/Dealer/List/:page?'
		,name: 'SettlementDealerList'
		,component: () => import('@/view/Settlement/Agent/SettlementDealerList.vue')
	}
	,{
		path: '/Settlement/Merchant/List/:page?'
		,name: 'SettlementMerchantList'
		,component: () => import('@/view/Settlement/Merchant/SettlementMerchantList.vue')
	}
	,{
		path: '/Settlement/Merchant/Payment/List/:pgMerchNo/:pgTid/:expPayDt/:page?'
		,name: 'SettlementMerchantPaymentList'
		,component: () => import('@/view/Settlement/Merchant/SettlementMerchantPaymentList.vue')
	}
	,{//
		path: '/Payments/History'
		,name: 'PaymentsHistory'
		,component: () => import('@/view/Payments/PaymentsHistory.vue')
	}
	,{//
		path: '/Payments/History/Detail/:index'
		,name: 'PaymentsHistoryDetail'
		,component: () => import('@/view/Payments/PaymentsHistoryDetail.vue')
	}
	,{//
		path: '/Payments/List'
		,name: 'PaymentsList'
		,component: () => import('@/view/Payments/PaymentsList.vue')
	}
	,{//
		path: '/Payments/Card'
		,name: 'PaymentsCard'
		,component: () => import('@/view/Payments/PaymentsCard.vue')
	}
	,{//
		path: '/Payments/Sms'
		,name: 'PaymentsSms'
		,component: () => import('@/view/Payments/PaymentsSms.vue')
	}
	,{//
		path: '/Payments/Sms/File'
		,name: 'PaymentsSmsFile'
		,component: () => import('@/view/Payments/PaymentsSmsFile.vue')
	}
	,{//
		path: '/Payments/Sms/Detail/:requestId/:requestKey'
		,name: 'PaymentsSmsDetail'
		,component: () => import('@/view/Payments/PaymentsSmsDetail.vue')
	}
	,{//
		path: '/MyPage'
		,name: 'MyPage'
		,component: () => import('@/view/Merchant/MerchantDetail.vue')
	}
	,{//
		path: '/Sales/List/:page?'
		,name: 'SalesList'
		,component: () => import('@/view/Sales/SalesList.vue')
	}
	,{//
		path: '/Sales/Detail'
		,name: 'SalesDetail'
		,component: () => import('@/view/Sales/SalesDetail.vue')
	}
	,{//
		path: '/Payments/Sales/List/:page?'
		,name: 'PaymentsSalesList'
		,component: () => import('@/view/Payments/Sales/PaymentsSalesList.vue')
	}
	,{//
		path: '/Payments/Sales/History/:userSeq/:orgSeq/:start/:end/:page?'
		,name: 'PaymentsSalesHistory'
		,component: () => import('@/view/Payments/Sales/PaymentsSalesHistory.vue')
	}
	,{
		path: '/Settlement/Merchant/List/:page?'
		,name: 'SettlementMerchantList'
		,component: () => import('@/view/Settlement/Merchant/SettlementMerchantList.vue')
	}
	,{
		path: '/Merchants/List/:page?'
		,name: 'MerchantsList'
		,component: () => import('@/view/Franchisee/FranchiseeList.vue')
	}
	,{
		path: '/Merchants/Apply/List/:page?'
		,name: 'MerchantsApplyList'
		,component: () => import('@/view/Merchants/Apply/MerchantsApplyList.vue')
	}
	,{
		path: '/Merchants/Apply/Item/:index?'
		,name: 'MerchantsApplyItem'
		,component: () => import('@/view/Merchants/Apply/MerchantsApplyItem.vue')
	}
	,{
		path: '/Merchants/Apply/Detail/:index'
		,name: 'MerchantsApplyDetail'
		,component: () => import('@/view/Merchants/Apply/MerchantsApplyDetail.vue')
	}
	,{
		path: '/Merchants/Terminal/List/:page?'
		,name: 'MerchantsTerminalList'
		,component: () => import('@/view/Merchants/Terminal/MerchantsTerminalList.vue')
	}
	,{
		path: '/Merchants/Terminal/Item'
		,name: 'MerchantsTerminalItem'
		,component: () => import('@/view/Merchants/Terminal/MerchantsTerminalItem.vue')
	}
	,{
		path: '/Notice/List/:page?'
		,name: 'NoticeList'
		,component: () => import('@/view/Notice/NoticeList.vue')
	}
	,{
		path: '/Notice/Detail/:bbsSeq'
		,name: 'NoticeDetail'
		,component: () => import('@/view/Notice/NoticeDetail.vue')
	}
	,{
		path: '*',
		name:'404', 
		component: () => import('@/view/Error/NotFound.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	next();
});

export default router
