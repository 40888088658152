<template>
	<div id="wrap">
		<!-- 오버레이 처리 -->
		<div id="overlay" 
			:class="{ active: overlay}"

			@click="overlay = false"
		></div>

		<!-- 참조 -->
		<div id="config"></div>

		<!-- 헤더 -->
		<div id="header"
			v-if="!program.not_header">
			<Header 
				:program="program"
				:user="config.user"

				@click="setUser"
			/>
		</div>

		<div id="body" 
			:class="program.not_header ? 'fullscreen' : 'dashboard'">
			<div id="side"
			>
				<Side 
					:user="config.user"
					:program="program"
				/>
			</div>
			<router-view
				:TOKEN="config.TOKEN"
				:callBack="config.callBack"
				:user="config.user"
				:overlay="overlay"
				:codes="codes"
				
				@setProgram="setProgram"
				@setOverlay="setOverlay"
				@setNotify="setNotify"
				@setAuth="setAuth"
			></router-view>
			
			<div id="footer"
				v-if="!program.not_header"
			>
				<div class="footer">
					<div class="link-list">
						<a href="">이용약관</a>
						<a href="">전자금융거래 이용약관</a>
						<a href="">개인정보 수집/이용안내</a>
					</div>
					<div class="ft-copyright">
						Copyright © {{ codes.metaInfo.copyright }} Corp. All Rights Reserved.
					</div>
				</div>
			</div>
		</div>
		<AlertMsg 
			:msg="notifyCondition.message" 
			:type="notifyCondition.type"
			
			@clearMsg="clearMsg"
		/>
	</div>
</template>

<script>

	import Header from './Header'
	import Side from './Side'
	import AlertMsg from '@/components/AlertMsg'
	
	export default{
		name: 'Layout'
		,props: ['config', 'codes']
		,components: { Header, Side, AlertMsg }
		,data: function(){
			return {
				program: {
				}
				,overlay: false
				,notifyCondition: {
					message: '메시지 입니다'
					,type: ''
				}
			}
		}
		,methods: {
			setProgram: function(program){
				console.log(program)
				this.program = program
			}
			,setOverlay: function(type){
			console.log('setOverlay type : ' + type)
			console.log('overlay  : ' + this.overlay)
				if(type){
					this.overlay = type
				}else{
					if(this.overlay){
						this.overlay = false
					}else{
						this.overlay = true
					}
				}
			}
			,setUser: function(type){
				this.$emit('setUser', type)
			}
			,setNotify: function({type, message}){
				this.notifyCondition = {
					message: message
					,type: type
				}
			}
			,setAuth: function(TOKEN){
				this.$emit('setAuth', TOKEN)
			}
			,clearMsg: function(){
				this.notifyCondition.message = ''
			}
		}
		,created: function(){
			console.log('layout')
		}
	}
	
</script>

