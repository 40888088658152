import axios from 'axios'

let domain			= 'franchisee.reappay.net'
let dev 			= 'https://api-dev-pg.reappay.net/merchant/v1'
let server 			= 'https://api-pg.reappay.net/merchant/v1'
let local			= 'http://localhost:8086/merchant/v1'

let dev_paylink 	= 'https://api-dev-pg.reappay.net/api/v1'
let server_paylink 	= 'https://api-pg.reappay.net/api/v1'
let local_paylink	= 'http://localhost:9081/api/v1'
				
let location = window.location.href

let baseUrl = ''
let paylinkUrl = ''

if(location.indexOf(domain) > -1) {
	baseUrl = server
	paylinkUrl = server_paylink
}else if(location.indexOf('localhost') > -1){
	baseUrl = local
	baseUrl = dev
	paylinkUrl = local_paylink
}else{
	baseUrl = dev
	paylinkUrl = dev_paylink
}

export async function Axios({ method, url, data, header, authorize, multipart, TOKEN, blob, paylink}){

	const instance = axios.create({
		baseURL: baseUrl
		,timeout: 5000
	})
	
	const getUrl = function(){
		if(paylink){
			return getPaylink() + url
		}else{
			return url
		}
	}
	
	const getParams = function(){
		if(method == 'get'){
			return data
		}
	}
	
	const getPaylink = function(){
		return paylinkUrl
	}
	
	const getData = function(){
		if(method != 'get'){
			const formData = new FormData();
			for(let key in data){
				formData.append(key, data[key])
			}
			if(multipart){
				return formData
			}else{
				return data
			}
		}
	}
	
	const getHeader = function(){
		
		let default_header = {
			'Content-Type': 'application/json'
			,'Access-Control-Allow-Origin' : '*'
		}
		
		if(authorize){
			default_header.Authorization = 'Bearer ' + (TOKEN ? TOKEN : sessionStorage.getItem('TOKEN'))
		}
		
		if(multipart){
			default_header['Content-Type'] = 'multipart/form-data'
		}
		
		if(blob){
			default_header['responseType'] = 'blob'
		}

		return header ? header : default_header
	}
	
	try{
		const result = await instance({
			method: method
			,url: getUrl()
			,params: getParams()
			,data: getData()
			,headers: getHeader()
		})
		
		if(result.status == 200){
			const data = result.data
			if(data.status == 200){
				return {success: true, data: data}
			}else{
				return {success: false, message: data.message}
			}
		}else if(result.status == 40120){
			return {success: false, message: '인증이 만료되었습니다'}
		}else{
			return {success: false, message: '통신오류: ' + result.status}
		}
	}catch(E){
		console.log('Axios result error')
		console.log(E)
		
		return {success: false, message: '통신오류: ' + E}
	}
}



