

export const language = {
	common: {
		askRemove: '삭제하시겠습니까?'
		,askCancel: '취소하시겠습니까?'
		,ready: '준비중입니다'
	}
	,menu: [
		{
			title: '결제 관리'
			,grade: [1, 2]
			,list: [
				{
					to: 'PaymentsHistory'
					,include: 'PaymentsHistoryDetail'
					,name: '완료/취소'
					,grade: [1, 2]
					,use: true
				}
				,{
					to: 'PaymentsHistoryDetail'
					,name: '결제 상세 정보'
					,grade: [1, 2]
					,use: false
				}
			]
		}
		,{
			title: '정산 관리'
			,grade: [1]
			,list: [
				{
					to: 'SettlementMerchantList'
					,name: '정산내역'
					,grade: [1, 2]
					,use: true
				}
			]
		}
		,{
			title: '영업점 관리'
			,grade: [1]
			,list: [
				{
					to: 'SalesList'
					,name: '영업점 목록'
					,grade: [1]
					,use: true
					,ready: false
				}
				,
				{
					to: 'PaymentsSalesList'
					,include: 'PaymentsSalesHistory'
					,name: '영업점 매출'
					,grade: [1]
					,use: true
					,ready: false
				}
			]
		}
		,{
			title: '결제'
			,grade: [1, 2, 3]
			,list: [
				{
					to: 'PaymentsCard'
					,name: '신용카드 수기결제'
					,grade: [1, 2]
					,use: true
				}
				,{
					to: 'PaymentsSms'
					,include: 'PaymentsSmsFile'
					,name: 'SMS 결제'
					,grade: [1, 2]
					,use: true
				}
				,{
					to: 'PaymentsList'
					,include: 'PaymentsSmsDetail'
					,name: 'SMS 결제요청 목록'
					,grade: [1, 2]
					,use: true
				}
				,{
					to: 'PaymentsSmsDetail'
					,name: 'SMS 결제 상세 정보'
					,grade: [1, 2]
					,use: false
				}
			]
		}
		,{
			title: '정보'
			,grade: [1, 2, 3]
			,list: [
				{
					to: 'MyPage'
					,include: 'SalesDetail'
					,name: '마이페이지'
					,grade: [1, 2]
					,use: true
				}
				,
				{
					to: 'NoticeList'
					,name: '공지사항'
					,grade: [1, 2]
					,use: true
				}
			]
		}
	]
}